import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import GatsbyLink from "./utility/GatsbyLink";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: 250,
    width: '80vw',
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflowY: 'hidden'
  },
  list: {
    flex: 1
  },
  listItem: {
    width: '100% !important'
  },
  listItemText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonLabel: {
    color: theme.palette.black,
  }
}));

export default function MobileMenuDrawer(props) {
  const {
    open,
    handleClose,
    handleOpen,
    menuItems,
    handleContact
  } = props;
  const classes = useStyles();

  return (
      <SwipeableDrawer
          ModalProps={{keepMounted: true}}
          id='menu-drawer'
          key='menu-drawer'
          anchor="right"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}>
        <div
            className={classes.content}
            role="presentation"
            id='menu-drawer-presentation'
        >
          <List className={classes.list}>
            {menuItems && menuItems.map((item, index) => (
                <ListItem component={GatsbyLink} to={item.uri} key={index} className={classes.listItem}
                          onClick={handleClose}>
                  <ListItemText primary={item.tab} className={classes.listItemText}
                                primaryTypographyProps={{variant: 'subtitle1', style: {fontWeight: 600, lineHeight: 1.5}}}/>
                </ListItem>
            ))}
          </List>
          <Button
              onClick={handleContact}
              variant="outlined"
              size="medium"
              color="primary"
              classes={{label: classes.buttonLabel, startIcon: classes.buttonLabel}}
              startIcon={<DraftsOutlinedIcon/>}>
            Let's Talk
          </Button>
        </div>
      </SwipeableDrawer>
  );
}
