// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-auroralib-js": () => import("./../../../src/templates/auroralib.js" /* webpackChunkName: "component---src-templates-auroralib-js" */),
  "component---src-templates-bond-goggles-js": () => import("./../../../src/templates/bond-goggles.js" /* webpackChunkName: "component---src-templates-bond-goggles-js" */),
  "component---src-templates-bond-goggles-quoter-js": () => import("./../../../src/templates/bond-goggles-quoter.js" /* webpackChunkName: "component---src-templates-bond-goggles-quoter-js" */),
  "component---src-templates-coming-soon-js": () => import("./../../../src/templates/coming-soon.js" /* webpackChunkName: "component---src-templates-coming-soon-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-portfolio-laboratory-js": () => import("./../../../src/templates/portfolio-laboratory.js" /* webpackChunkName: "component---src-templates-portfolio-laboratory-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */)
}

