import React from 'react';
import {Formik} from "formik";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
};

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.white,
    "&:hover $notchedOutline": {
      borderColor: theme.palette.primary.main
    }
  },
  input: {
    fontFamily: theme.typography.body1.fontFamily
  },
  notchedOutline: {
    borderColor: theme.palette.white,
  },
  iconButton: {
    "&:disabled": {
      color: theme.palette.white,
      opacity: 0.4
    }
  }
}));

export default function ConnectForm({...rest}) {
  const classes = useStyles();

  return (
      <Formik
          initialValues={{email: ''}}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            fetch("/?no-cache=1", {
              method: 'POST',
              headers: {'Content-Type': 'application/x-www-form-urlencoded'},
              body: encode({
                'form-name': 'connect',
                ...values,
              }),
            })
                .then(() => {
                  setSubmitting(false);
                  resetForm({
                    values: {email: ''}
                  })
                })
                .catch(error => {
                  console.log(error);
                  alert('Error: Please Try Again!');
                  setSubmitting(false)
                })
          }}
          {...rest}
      >
        {({
            isSubmitting,
            handleSubmit,
            handleChange,
            values
          }) => {
          return (
              <form onSubmit={handleSubmit} name="connect" netlify-honeypot="bot-field" data-netlify="true">
                <TextField
                    onChange={handleChange}
                    value={values.email}
                    variant={"outlined"}
                    placeholder='Email'
                    color="primary"
                    name="email"
                    id="email"
                    type="email"
                    fullWidth
                    required
                    InputProps={{
                      className: classes.input,
                      classes: {
                        root: classes.textFieldRoot,
                        notchedOutline: classes.notchedOutline
                      },
                      endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton className={classes.iconButton} color='inherit' size='medium' type='submit'
                                        disabled={isSubmitting}>
                              <DraftsOutlinedIcon/>
                            </IconButton>
                          </InputAdornment>
                      )
                    }}
                />
              </form>
          )
        }}
      </Formik>
  )
}
