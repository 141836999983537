import React from 'react';
import MuiLink from '@material-ui/core/Link';
import { Link  } from 'gatsby';

const linkStyles = {
  display: 'inline-block',
  width: 'fit-content',
};

const GatsbyLink = React.forwardRef(function GatsbyLink(props, ref) {
  const {to, href, url, ...rest} = props;

  if (to) {
    return <MuiLink color='inherit' underline='none' style={linkStyles} component={Link} ref={ref} {...props} />;
  }

  if (url) {
    if (url.startsWith('/')) {
      return <MuiLink color='inherit' underline='none' style={linkStyles} component={Link} ref={ref} to={url} {...rest} />;
    } else if (url.startsWith('https://') || url.startsWith('http://') || url.startsWith('ftp://')) {
      return <MuiLink color='inherit' underline='none' style={linkStyles} ref={ref} href={url} {...rest} />;
    }
  }

  return <MuiLink color='inherit' underline='none' style={linkStyles} ref={ref} {...props} />;
});

export default GatsbyLink;
