import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import ContactUsDrawer from "./ContactUsDrawer";
import Header from "./Header";
import Footer from "./Footer";
import ErrorBoundary from "./utility/ErrorBoundary";
import {graphql, StaticQuery} from "gatsby";
import MobileMenuDrawer from "./MobileMenuDrawer";
//import { useIdentityContext } from "react-netlify-identity-widget"
//import IdentityWidget from "./utility/IdentityWidget/IdentityWidget";
import { navigate } from "gatsby"

const headerTabs = [
  {uri: '/home', tab: 'home'},
  {uri: '/bond-goggles', tab: 'bond goggles'},
  {uri: '/portfolio-laboratory', tab: 'bond portfolio laboratory'},
  {uri: '/bond-goggles-quoter', tab: 'bond goggles quoter'},
  {uri: '/auroralib', tab: 'auroralib'},
  {uri: '/about', tab: 'about us'},
];

export const headerHeight = 86;

const Layout = ({children, uri}) => {
  const [contactDrawerOpen, setContactDrawerOpen] = useState(false);
  const [mobileMenuDrawerOpen, setMobileMenuDrawerOpen] = useState(false);
  //const [identityDialog, setIdentityDialog] = React.useState(false)
  //const identity = useIdentityContext()

  const pageTab = () => {
    switch (uri) {
      case '/home':
        return 0;
      case '/bond-goggles':
        return 1;
      case '/portfolio-laboratory':
        return 2;
      case '/bond-goggles-quoter':
        return 3;
      case '/auroralib':
        return 4;
      case '/about':
        return 5;
      default:
        return 0;
    }
  };

  const [selected, setSelected] = useState(pageTab);

  useEffect(() => {
    setSelected(pageTab);
  }, [uri]);

  //useEffect(() => {
  //  if (!identity.isLoggedIn) {
  //    setIdentityDialog(true);
  //  } else {
  //    setIdentityDialog(false);
  //  }
  //},[identity])

  //const handleCloseIdentity = (e) => {
  //  if (identity.isLoggedIn) {
  //    setIdentityDialog(false);
  //  } else if (!identity.isLoggedIn && e.target.type === 'submit'){
  //    setIdentityDialog(false);
  //    navigate('/');
  //  }
  //}

  return (
      <StaticQuery
          query={graphql`
          query Layout {
            prismicFooter {
              data {
                  address
                  email
                  social {
                      type
                      url {
                          url
                      }
                  }
                  copyright
              }
            }
          }
        `}
          render={data => (
              <ErrorBoundary>
                <Header tabs={headerTabs} height={headerHeight}
                onContactClick={() => setContactDrawerOpen(true)}
                onMenuClick={() => setMobileMenuDrawerOpen(true)}
                selected={selected} setSelected={setSelected}/>
                <main style={{minHeight: '100vh'}}>
                  <div style={{height: headerHeight, width: '100%'}}/>
                  {children}
                </main>
                {data && data.prismicFooter && data.prismicFooter.data &&
                <Footer
                    address={data.prismicFooter.data.address}
                    mail={data.prismicFooter.data.email}
                    social={data.prismicFooter.data.social}
                    privacyLink='/privacy-policy'
                    copyright={data.prismicFooter.data.copyright}/>
                }
                <MobileMenuDrawer
                    open={mobileMenuDrawerOpen}
                    handleClose={() => setMobileMenuDrawerOpen(false)}
                    handleOpen={() => setMobileMenuDrawerOpen(true)}
                    menuItems={headerTabs}
                    handleContact={() => setContactDrawerOpen(true)}/>
                <ContactUsDrawer
                    open={contactDrawerOpen}
                    handleClose={() => setContactDrawerOpen(false)}
                    handleOpen={() => setContactDrawerOpen(false)}/>
              </ErrorBoundary>
          )}
      />
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
