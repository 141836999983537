import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {ContactSupport} from "@material-ui/icons";
import ContactForm from "./forms/ContactForm";
import {SwipeableDrawer} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: 575,
    width: '80vw',
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflowY: 'hidden'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 64
  },
  textField: {
    marginTop: theme.spacing(4),
  },
  submitButton: {
    marginTop: theme.spacing(6),
  }
}));

export default function ContactUsDrawer(props) {
  const {
    open,
    handleClose,
    handleOpen,
  } = props;
  const classes = useStyles();

  return (
      <SwipeableDrawer
          disableSwipeToOpen
          ModalProps={{keepMounted: true}}
          id='contact-us-drawer'
          key='contact-us-drawer'
          anchor="right"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}>
        <div
            className={classes.content}
            role="presentation"
            id='contact-drawer-presentation'
        >
          <div className={classes.titleContainer}>
            <Typography variant={'h2'}>Contact Us</Typography>
            <ContactSupport color="primary" fontSize="inherit"/>
          </div>
          <ContactForm handleClose={handleClose}/>
        </div>
      </SwipeableDrawer>
  );
}
