import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Logo from '../images/bond-logo.svg'
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import {Link} from "gatsby";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Fade from "@material-ui/core/Fade";
import {useTheme} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: "none",
    flexGrow: 1,
  },
  appBarContent: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    objectPosition: 'left',
    objectFit: 'contain',
    maxHeight: 64,
    height: 64
  },
  tabIndicator: {
    height: 6,
  },
  tabs: {
    maxWidth: `calc(100% - ${theme.spacing(19)}px)`,
  },
  tab: {
    textTransform: 'lowercase',
    width: 124,
    minWidth: 'unset',
    maxWidth: 'unset',
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 16,
    lineHeight: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    marginRight: theme.spacing(4)
  },
  button: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(17),
  },
  buttonLabel: {
    color: theme.palette.black,
  }
}));

export default function Header(props) {
  const {tabs, height = 86, onContactClick, onMenuClick, selected = 0, setSelected, ...rest} = props;
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const theme = useTheme();
  const matchesDesk = useMediaQuery(theme.breakpoints.up('md'));


  const handleChange = (ev, newVal) => {
    setSelectedTab(newVal);
    setSelected(newVal);
  };

  useEffect(() => {
    if (selected !== selectedTab) {
      setSelectedTab(selected);
    }
  }, [selected]);

  const heightStyle = {height: height};

  return (
      <header {...rest}>
        <AppBar position='fixed' color={"inherit"} className={classes.appBar}>
          <Toolbar>
            <Grid container style={heightStyle} alignItems={'center'}>
              <Grid item component='img' xs={4} md={1} src={Logo} alt='' className={classes.icon}/>
              <Hidden mdUp initialWidth={'xl'}>
                <Grid item xs={4}/>
                <Grid item container xs={4} alignItems={'center'} justify={"flex-end"}>
                  <Button
                      className={classes.button}
                      onClick={onMenuClick}
                      variant="outlined"
                      size="medium"
                      color="primary"
                      classes={{label: classes.buttonLabel, startIcon: classes.buttonLabel}}
                      endIcon={<MenuIcon/>}>
                    Menu
                  </Button>
                </Grid>
              </Hidden>
              <Hidden smDown initialWidth={'xs'}>
                <Fade in={matchesDesk} style={{transitionDuration: '300ms'}}>
                  <Grid item container md={11} alignItems='center' justify='flex-end'>
                    <Tabs
                        className={classes.tabs}
                        variant="scrollable"
                        scrollButtons="auto"
                        value={selectedTab}
                        onChange={handleChange}
                        indicatorColor='primary'
                        classes={{indicator: classes.tabIndicator}}>
                      {tabs && tabs.length > 0 && tabs.map((tab, index) => {
                        return (
                            <Tab
                                to={tab.uri}
                                component={Link}
                                label={tab.tab}
                                variant='h5'
                                className={classes.tab}
                                style={heightStyle}
                                value={index}
                                key={index}
                                disableRipple
                                disableFocusRipple/>
                        )
                      })}
                    </Tabs>
                    <Button
                        onClick={onContactClick}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        classes={{label: classes.buttonLabel, startIcon: classes.buttonLabel}}
                        className={classes.button}
                        startIcon={<DraftsOutlinedIcon/>}>
                      Let's Talk
                    </Button>
                  </Grid>
                </Fade>
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
      </header>
  );
}
