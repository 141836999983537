import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Logo from '../images/bond-logo-white.svg'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Facebook, Instagram, LinkedIn, Twitter} from "@material-ui/icons";
import GatsbyLink from "./utility/GatsbyLink";
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import ConnectForm from "./forms/ConnectForm";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
  },
  mainGrid: {
    padding: theme.spacing(10),
  },
  gridMobile: {
    padding: theme.spacing(4)
  },
  copyrightGrid: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(1),
  },
  rowPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  logo: {
    objectFit: 'contain',
    objectPosition: 'left',
    maxHeight: 64,
    height: 64,
  },
  logoMobile: {
    objectFit: 'contain',
    objectPosition: 'center',
    maxHeight: 64,
    height: 64,
  },
  addressMobile: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  social: {
    marginRight: theme.spacing(4)
  },
  textFieldRoot: {
    color: theme.palette.white,
    "&:hover $notchedOutline": {
      borderColor: theme.palette.primary.main
    }
  },
  notchedOutline: {
    borderColor: theme.palette.white,
  },
  copyrights: {
    fontWeight: 300,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  poweredBy: {
    fontWeight: 300,
    fontSize: 11,
    letterSpacing: 2
  },
  centeredText: {
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  socialContainer: {
  },
  connectMobile: {
    fontWeight: 'normal',
    marginTop: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: theme.spacing(4)
  }
}));

export default function Footer(props) {
  const {address, mail, social, privacyLink, copyright, ...rest} = props;

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  function socialComponents() {
    if (social) {
      return social.map((socialObject, index) => {
        switch (socialObject.type) {
          case 'facebook':
            return <GatsbyLink url={socialObject.url ? socialObject.url.url : null} key={index}
                               className={!matches ? classes.social : null}>
              <Facebook fontSize="large"/>
            </GatsbyLink>;
          case 'instagram':
            return <GatsbyLink url={socialObject.url ? socialObject.url.url : null} key={index}
                               className={!matches ? classes.social : null}>
              <Instagram fontSize="large"/>
            </GatsbyLink>;
          case 'twitter':
            return <GatsbyLink url={socialObject.url ? socialObject.url.url : null} key={index}
                               className={!matches ? classes.social : null}>
              <Twitter fontSize="large"/>
            </GatsbyLink>;
          case 'linkedin':
            return <GatsbyLink url={socialObject.url ? socialObject.url.url : null} key={index}
                               className={!matches ? classes.social : null}>
              <LinkedIn fontSize="large"/>
            </GatsbyLink>;
          default:
            return ''
        }
      })
    }
    return ''
  }

  return (
      <footer className={classes.root} {...rest}>
        <Grid container className={!matches ? classes.mainGrid : classes.gridMobile}>
          <Grid item container xs={12} md={12} className={classes.rowPadding} alignItems={'flex-end'}>
            <Grid item md={3} xs={12} component={'img'} className={matches ? classes.logoMobile : classes.logo}
                  src={Logo} alt=''/>
            <Hidden mdUp initialWidth={'lg'}>
              <Grid item xs={12} className={clsx(classes.addressMobile, classes.centeredText)}>
                {address && address.split('\n').flatMap((text, index) => {
                  return (
                      <Typography variant={'subtitle2'} key={index} color='inherit'>
                        {text}
                      </Typography>
                  )
                })}
              </Grid>
              <Grid item container xs={12} justify={"center"} className={classes.flex}>
                <GatsbyLink href={`mailto:${mail}`}>
                  <Typography variant={'subtitle2'} color='inherit'>
                    {mail}
                  </Typography>
                </GatsbyLink>
              </Grid>
            </Hidden>
            <Grid item container xs={12} md={6} justify={matches ? 'space-evenly' : 'flex-start'}
                  className={!matches ? classes.socialContainer : null}>
              {socialComponents()}
            </Grid>
            <Hidden smDown initialWidth={'lg'}>
              <Grid item md={3}>
                <Typography variant={'h5'} color='inherit'>
                  Connect with us
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item container md={12} className={classes.rowPadding}>
            <Grid item md={3}>
              <Hidden smDown initialWidth={'lg'}>
                <Grid item md={12} className={classes.marginBottom}>
                  {address && address.split('\n').flatMap((text, index) => {
                    return (
                        <Typography variant={'subtitle2'} key={index} color='inherit'>
                          {text}
                        </Typography>
                    )
                  })}
                </Grid>
                <GatsbyLink href={`mailto:${mail}`}>
                  <Typography variant={'subtitle2'} color='inherit'>
                    {mail}
                  </Typography>
                </GatsbyLink>
              </Hidden>
            </Grid>
            <Grid item container md={6} direction='column' alignItems={matches ? "center" : 'flex-start'}
                  className={!matches ? classes.marginBottom : null}>
              <GatsbyLink className={matches ? classes.terms : classes.termsMobile} url={privacyLink}>
                <Typography variant={'subtitle2'} color='inherit'>
                  Privacy Policy
                </Typography>
              </GatsbyLink>
              <Hidden mdUp initialWidth={'lg'}>
                <Typography className={classes.connectMobile} variant={'h5'} color='inherit'>
                  Connect with us
                </Typography>
              </Hidden>
            </Grid>
            <Grid item container xs={12} md={3} justify={!matches ? 'flex-start' : 'center'}
                  className={matches ? classes.marginBottom : null}>
              <ConnectForm className={classes.form}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={!matches ? classes.copyrightGrid : classes.gridMobile}>
          <Hidden smDown initialWidth={'lg'}>
            <Grid item md={3}/>
            <Grid item md={6}/>
          </Hidden>
          <Grid item container xs={12} md={3} justify={!matches ? 'flex-start' : 'center'}>
            <Typography variant={'subtitle2'} color='inherit' className={classes.copyrights}>
              {copyright}
            </Typography>
          </Grid>
          <Hidden smDown initialWidth={'lg'}>
            <Grid item md={3}/>
            <Grid item md={6}/>
          </Hidden>
          <Grid item container xs={12} md={3} justify={!matches ? 'flex-start' : 'center'}
                className={!matches ? classes.marginBottom : null}>
            <GatsbyLink url={'https://www.quintessential.gr'}>
              <Typography variant={'subtitle2'} color='inherit' className={clsx(classes.copyrights, classes.poweredBy)}>
                Powered by Quintessential SFT
              </Typography>
            </GatsbyLink>
          </Grid>
        </Grid>
      </footer>
  );
}

